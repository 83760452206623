class ContactPageComponent {
	constructor(context, id) {
		this.context = context
		this.id = id

		this.init()
	}

	init() {
		this.el = document.getElementById(this.id)
		console.log(this.el)
	}
}

export { ContactPageComponent }
